.controls-container {
  --highlight-height: 0;
  --highlight-y-pos: 0;
}

.controls::before {
  height: var(--highlight-height);
  transform: translateY(var(--highlight-y-pos));
}

.controls::before {
  transition:
    transform 0.3s ease,
    width 0.3s ease;
}
