.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

.controls::before {
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
}

.controls.ready::before {
  transition:
    transform 0.3s ease,
    width 0.3s ease;
}
